export default {
  path: '/business',
  component: (resolve) => require(['@/views/business/index.vue'], resolve),
  redirect: '/business/dashboard',
  children: [
    {
      path: '/business/dashboard',
      name: 'Dashboard',
      component: (resolve) => require(['@/views/business/dashboard/index.vue'], resolve)
    },
    {
      path: '/business/logs',
      name: 'Logs',
      component: (resolve) => require(['@/views/business/logs/index.vue'], resolve)
    },
    {
      path: '/business/client/client-list',
      component: (resolve) => require(['@/views/business/client/list/ClientList.vue'], resolve)
    },
    {
      path: '/business/client/client-verification',
      component: (resolve) =>
        require(['@/views/business/client/verification/ClientVerification.vue'], resolve)
    },
    {
      path: '/business/client/cancellation-requests',
      component: (resolve) => require(['@/views/business/client/cancellations/index.vue'], resolve)
    },
    {
      path: '/business/client/feedback',
      component: (resolve) => require(['@/views/business/client/feedback/index.vue'], resolve)
    },
    {
      path: '/business/client/prospects',
      component: (resolve) =>
        require(['@/views/business/client/prospects/ProspectsList.vue'], resolve)
    },
    {
      path: '/business/reports/overview',
      component: (resolve) => require(['@/views/business/reports/overview/index.vue'], resolve)
    },
    {
      path: '/business/reports/clients-funnel',
      component: (resolve) =>
        require(['@/views/business/reports/clients-funnel/index.vue'], resolve)
    },
    {
      path: '/business/reports/products-report',
      component: (resolve) =>
        require(['@/views/business/reports/products-report/index.vue'], resolve)
    },

    {
      path: '/business/reports/clients-report',
      component: (resolve) =>
        require(['@/views/business/reports/clients/ClientsReport.vue'], resolve)
    },
    {
      path: '/business/reports/packages-report',
      component: (resolve) =>
        require(['@/views/business/reports/packages/PackagesReport.vue'], resolve)
    },
    {
      path: '/business/reports/services-report',
      component: (resolve) =>
        require(['@/views/business/reports/services/ServicesReport.vue'], resolve)
    },
    {
      path: '/business/reports/product-report',
      component: (resolve) =>
        require(['@/views/business/reports/products/productsReport.vue'], resolve)
    },
    {
      path: '/business/reports/keywords-report',
      component: (resolve) => require(['@/views/business/reports/keywords/index.vue'], resolve)
    },
    {
      path: '/business/affiliate/affiliate-list',
      component: (resolve) =>
        require(['@/views/business/affiliate/affiliateList/index.vue'], resolve)
    },
    {
      path: '/business/affiliate/aff-order-list',
      component: (resolve) =>
        require(['@/views/business/affiliate/affOrderList/index.vue'], resolve)
    },
    {
      path: '/business/affiliate/withdraw-list',
      component: (resolve) =>
        require(['@/views/business/affiliate/withdrawalRequestList/index.vue'], resolve)
    },
    {
      path: '/business/ticket/ticket-list',
      component: (resolve) => require(['@/views/business/tickets/TicketList.vue'], resolve)
    },
    {
      path: '/business/ticket/ticket-report',
      component: (resolve) => require(['@/views/business/tickets/TicketReportList.vue'], resolve)
    },
    {
      path: '/business/customer-service-report/clients-report/workload',
      component: (resolve) =>
        require(['@/views/business/customer-service/workload/WorkloadReport.vue'], resolve)
    },
    {
      path: '/business/customer-service-report/clients-report/workload-by-agent',
      component: (resolve) =>
        require(['@/views/business/customer-service/workload/WorkloadByAgentReport.vue'], resolve)
    },
    {
      path: '/business/ticket/feedback-list',
      component: (resolve) => require(['@/views/business/tickets/TicketFeedbackList.vue'], resolve)
    },
    {
      path: '/business/ticket/agent-ticket-report',
      component: (resolve) =>
        require(['@/views/business/tickets/AgentTicketReportList.vue'], resolve)
    },
    {
      path: '/business/task/task-list',
      component: (resolve) => require(['@/views/business/task/TaskList.vue'], resolve)
    },
    {
      path: '/business/client/:clientId',
      redirect: '/business/client/:clientId/overview',
      component: (resolve) => require(['@/views/business/client/profile/index.vue'], resolve),
      children: [
        {
          path: '/business/client/:clientId/profile',
          component: (resolve) =>
            require(['@/views/business/client/profile/clientProfile/Profile.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/overview',
          component: (resolve) =>
            require(['@/views/business/client/profile/overview/index.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/affiliate',
          component: (resolve) =>
            require(['@/views/business/client/profile/affiliate/index.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/packages',
          component: (resolve) =>
            require(['@/views/business/client/profile/packages/index.vue'], resolve),
          redirect: '/business/client/:clientId/packages/list',
          children: [
            {
              path: '/business/client/:clientId/packages/list',
              component: (resolve) =>
                require(['@/views/business/client/profile/packages/list/index.vue'], resolve)
            },
            {
              path: '/business/client/:clientId/packages/all-list',
              component: (resolve) =>
                require(['@/views/business/client/profile/packages/list/AllList.vue'], resolve)
            },
            // {
            //   path: '/business/client/:clientId/packages/:packageId',
            //   component: (resolve) =>
            //     require(['@/views/business/client/profile/packages/detail/index.vue'], resolve)
            // },
            {
              path: '/business/client/:clientId/packages/:packageId',
              component: (resolve) =>
                require(['@/views/business/client/profile/packages/PackageDetail.vue'], resolve)
            }
            // {
            //   path: '/business/client/:clientId/packages/server/:packageId',
            //   component: (resolve) =>
            //     require([
            //       '@/views/business/client/profile/packages/serverDetail/index.vue'
            //     ], resolve)
            // }
          ]
        },
        {
          path: '/business/client/:clientId/billing',
          component: (resolve) =>
            require(['@/views/business/client/profile/billing/index.vue'], resolve),
          redirect: '/business/client/:clientId/billing/panel',
          children: [
            {
              path: '/business/client/:clientId/billing/panel',
              component: (resolve) =>
                require([
                  '@/views/business/client/profile/billing/panel_billing/index.vue'
                ], resolve),
              redirect: '/business/client/:clientId/billing/panel/invoice-list',
              children: [
                {
                  path: '/business/client/:clientId/billing/panel/invoice-list',
                  component: (resolve) =>
                    require([
                      '@/views/business/client/profile/billing/panel_billing/InvoiceList.vue'
                    ], resolve)
                },
                {
                  path: '/business/client/:clientId/billing/panel/payment',
                  component: (resolve) =>
                    require([
                      '@/views/business/client/profile/billing/panel_billing/PaymentSettings.vue'
                    ], resolve)
                },
                {
                  path: '/business/client/:clientId/billing/panel/credits',
                  component: (resolve) =>
                    require([
                      '@/views/business/client/profile/billing/panel_billing/Credits.vue'
                    ], resolve)
                }
              ]
            },
            {
              path: '/business/client/:clientId/billing/invoice/:invoiceId',
              component: (resolve) =>
                require(['@/views/business/client/profile/billing/invoice/index.vue'], resolve)
            }
          ]
        },
        {
          path: '/business/client/:clientId/billing',
          component: (resolve) => require(['@/views/business/client/profile/Email.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/ticket',
          component: (resolve) =>
            require(['@/views/business/client/profile/ticket/index.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/settings',
          component: (resolve) =>
            require(['@/views/business/client/profile/settings/index.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/emails',
          component: (resolve) => require(['@/views/business/client/profile/Email.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/chats',
          component: (resolve) =>
            require(['@/views/business/client/profile/chats/index.vue'], resolve)
        },
        {
          path: '/business/client/:clientId/activities',
          component: (resolve) => require(['@/views/business/client/profile/Activity.vue'], resolve)
        }
      ]
    },
    {
      path: '/business/billing/taxes',
      component: (resolve) => require(['@/views/business/billing/taxes/index.vue'], resolve)
    },
    {
      path: '/business/billing/billing-trial',
      component: (resolve) =>
        require(['@/views/business/billing/trial/TrialConfiguration.vue'], resolve)
    },
    {
      path: '/business/billing/billing-invoice',
      component: (resolve) => require(['@/views/business/billing/invoice/InvoiceList.vue'], resolve)
    },
    {
      path: '/business/billing/paypal-subscriptions',
      component: (resolve) => require(['@/views/business/billing/paypal/index.vue'], resolve)
    },
    {
      path: '/business/billing/billing-routine',
      component: (resolve) => require(['@/views/business/billing/routingjob/index.vue'], resolve),
      redirect: '/business/billing/billing-routine/list',
      children: [
        {
          path: '/business/billing/billing-routine/list',
          component: (resolve) =>
            require(['@/views/business/billing/routingjob/RoutingJobList.vue'], resolve)
        },
        {
          path: '/business/billing/billing-routine/:jobId',
          component: (resolve) =>
            require(['@/views/business/billing/routingjob/RoutingDetail.vue'], resolve)
        }
      ]
    },
    {
      path: '/business/email-tool/template',
      component: (resolve) => require(['@/views/business/email-tool/template/index.vue'], resolve),
      redirect: '/business/email-tool/template/list',
      children: [
        {
          path: '/business/email-tool/template/list',
          component: (resolve) =>
            require(['@/views/business/email-tool/template/List.vue'], resolve)
        },
        {
          path: '/business/email-tool/template/:tId',
          component: (resolve) =>
            require(['@/views/business/email-tool/template/EmailDetail.vue'], resolve)
        }
      ]
    },
    {
      path: '/business/email-tool/task',
      component: (resolve) =>
        require(['@/views/business/email-tool/task/EmailTaskList.vue'], resolve)
    },
    {
      path: '/business/utilities/sales-website',
      component: (resolve) =>
        require(['@/views/business/utilities/salesWebsite/index.vue'], resolve)
    },
    {
      path: '/business/email-tool/task/:jId',
      component: (resolve) => require(['@/views/business/email-tool/task/Config.vue'], resolve)
    },
    {
      path: '/business/utilities/coupons',
      component: (resolve) => require(['@/views/business/utilities/coupons/index.vue'], resolve)
    },
    {
      path: '/business/settings/billing-system',
      component: (resolve) => require(['@/views/business/settings/system/index.vue'], resolve)
    },
    {
      path: '/business/settings/backup-cycle',
      component: (resolve) => require(['@/views/business/settings/backup/index.vue'], resolve)
    },
    {
      path: '/business/settings/tag-mg',
      component: (resolve) => require(['@/views/business/settings/tags/index.vue'], resolve)
    },
    {
      path: '/business/packages/additional-software-list',
      component: (resolve) =>
        require(['@/views/business/packages/additionalSoftware/SoftwareList.vue'], resolve)
    },
    {
      path: '/business/packages/additional-hardware-list',
      component: (resolve) =>
        require(['@/views/business/packages/additionalHardware/HardwareList.vue'], resolve)
    },
    {
      path: '/business/packages/ssl-list',
      component: (resolve) => require(['@/views/business/packages/sslList/index.vue'], resolve)
    },
    {
      path: '/business/packages/free-dns-list',
      component: (resolve) => require(['@/views/business/packages/freeDNSList/index.vue'], resolve)
    },
    {
      path: '/business/packages/domain-list',
      component: (resolve) => require(['@/views/business/packages/domainList/index.vue'], resolve)
    },
    {
      path: '/business/packages/overused-vps-list',
      component: (resolve) =>
        require(['@/views/business/packages/overusedVPSList/index.vue'], resolve)
    },
    {
      path: '/business/packages/abnormal-app-list',
      component: (resolve) =>
        require(['@/views/business/packages/abnormalApp/AbnormalList.vue'], resolve)
    },
    {
      path: '/business/packages/abnormal-backup-list',
      component: (resolve) =>
        require(['@/views/business/packages/abnormalBackup/AbnormalList.vue'], resolve)
    },
    {
      path: '/business/packages/assist-service-list',
      component: (resolve) =>
        require(['@/views/business/packages/assistService/AssistService.vue'], resolve)
    },
    {
      path: '/business/packages/cloud-migration',
      component: (resolve) =>
        require(['@/views/business/packages/cloudMigration/index.vue'], resolve)
    },
    {
      path: '/business/packages/package-list',
      component: (resolve) => require(['@/views/business/packages/list/index.vue'], resolve)
    },
    {
      path: '/business/packages/vps-list',
      component: (resolve) => require(['@/views/business/packages/vpsList/index.vue'], resolve)
    },
    {
      path: '/business/packages/clone-package-list',
      component: (resolve) =>
        require(['@/views/business/packages/clonePackagesList/index.vue'], resolve)
    },
    {
      path: '/business/packages/custom-list',
      component: (resolve) => require(['@/views/business/packages/custom/index.vue'], resolve)
    },
    {
      path: '/business/packages/servers/server-list',
      component: (resolve) => require(['@/views/business/packages/servers/index.vue'], resolve)
    },
    {
      path: '/business/packages/migration-node',
      component: (resolve) =>
        require(['@/views/business/packages/migrationNode/index.vue'], resolve)
    },
    {
      path: '/business/product-management/products',
      component: (resolve) =>
        require(['@/views/business/products/product/ProductList.vue'], resolve)
    },
    {
      path: '/business/product-management/products/:productId',
      component: (resolve) =>
        require(['@/views/business/products/product/ProductDetail.vue'], resolve)
    },
    {
      path: '/business/product-management/plans',
      component: (resolve) => require(['@/views/business/products/plan/PlanList.vue'], resolve)
    },
    {
      path: '/business/product-management/plans/:planId',
      component: (resolve) => require(['@/views/business/products/plan/PlanDetail.vue'], resolve)
    },
    {
      path: '/business/product-management/specifications',
      component: (resolve) =>
        require(['@/views/business/products/specifications/index.vue'], resolve)
    }
  ]
};
