const app_key = process.env.VUE_APP_KEY;
const shellURL = process.env.VUE_APP_SHELL;
const chatURL = process.env.VUE_APP_CHAT;
const limitSize = {
  errMsg: 'File size exceeds 20M',
  size: 1024 * 1024 * 20
};
const domain = {
  SSO: process.env.VUE_APP_SSO
};
const status = {
  Pending: {},
  Active: {},
  Suspended: {},
  Cancelled: {}
};
const perUlrMap = {
  GPU: 'gpu-servers',
  APP: 'applications',
  VM: 'myvps',
  'Bare Metal': 'dedicated-servers',
  SSL: 'ssl',
  Domain: 'domain'
};
const packageCategory = ['Database', 'CMS'];
const company = ['CCS', 'DBM'];
const eventTypeDown = ['None', 'Reboot', 'Hardware Error', 'OS issue', 'Network issue', 'Others'];
const eventTypeWarning = [
  'Others',
  'Backup Disk Abnormal',
  'Disk Abnormal',
  'IPMI Error',
  'Service Down',
  'VPS Deployment Abnormal'
];
const packageStatus = ['Pending', 'Active', 'Suspended', 'Cancelled', 'Unconfirmed'];
const commissionStatus = ['Pending', 'Invalid', 'Available', 'Withdrawing', 'Withdrawn'];
const withdrawalStatus = ['Pending', 'Approved', 'Rejected', 'Cancelled'];
const sslStatus = ['Initial', 'Pending', 'Active', 'Reissue pending'];
const sslPackageStatus = ['Pending', 'Unconfirmed', 'Active', 'Suspended', 'Cancelled'];
const domainStatus = ['Unpaid', 'Initial', 'Pending', 'Active', 'Suspended', 'Cancelled'];
const domainPackageStatus = ['Pending', 'Unconfirmed', 'Active', 'Suspended', 'Cancelled'];
const deployStatus = [
  'Deploying',
  'Success',
  'Failure',
  'Timeout',
  'Deleting',
  'Deleted',
  'DeleteFailed'
];
const invoiceStatus = [
  'Unpaid',
  'Partially Paid',
  'Paid',
  'Pending Refund',
  'Partially Refunded',
  'Fully Refunded',
  'Cancelled',
  'Charged Back',
  'Draft'
];
const invoiceTypes = [
  'Manual',
  'Addon',
  'Product_Addon',
  'Upgrade',
  'Billing Cycle',
  'Membership',
  'DailyBackupAddon',
  'Add Funds'
];
const clientStatus = ['Good', 'Uncertain', 'Pending', 'Unverified', 'Closed'];
const orderStatus = ['Pending', 'Active', 'Cancelled', 'Fraud', 'Invalid'];
const clientType = ['reseller', 'client', 'test'];
const serviceStatus = ['Pending', 'Suspended', 'Cancelled', 'Active'];
const serviceType = ['Web Service', 'Microservice', 'Backend Service', 'Third-Party Service'];
const cancellationTypes = ['Immediate', 'End of Billing Cycle'];
const jobType = [
  'Account Profile Reminding',
  'Database Mart Reviews Invitation',
  'Invoice Create',
  'Invoice Capture',
  'Invoices Generation',
  'Invoice Overdue Reminder',
  'RBD Backup',
  'Invoice Reminder',
  'Package Suspension',
  'Package Termination',
  'Retention',
  'CronResourceClean',
  'ManualCheckAbnormal',
  'OvertimeBackupClean',
  'Pending Package Cancellation',
  'VPS Backup',
  'VPS Backup Deletion',
  'Clear Up VPS Backups',
  'Membership Cancellation',
  'Membership Invoices Generation'
];
const jobStatus = ['Normal', 'Abnormal'];
const paymentMethod = [
  'Credit Card',
  'PayPal',
  'Check',
  'AliPay',
  'WeChat Pay',
  'Bank Transfer',
  'Credit Applied'
];
const cancellationReasons = [
  'Overdue On Payment',
  'Cancellation Request',
  'Failed Identity Verification',
  'Cancelled by Agent'
];
const FeedBackType = ['New Feature', 'System Bug', 'Testimonials', 'Other'];
const rentalServerStatus = ['Active', 'Free', 'Cancelled'];
const dedicatedServiceStatus = [
  'Active',
  'Pending',
  'Free',
  'Suspended',
  'Cancelled',
  'Repair Pending',
  'Reloading',
  'Transferring',
  'Deployment Abnormal',
  'Reserved'
];
const productType = ['APP', 'VM', 'Bare Metal', 'Custom', 'SSL', 'Domain'];
const dedicatedDeploymentStatus = [
  'Active',
  'Pending',
  'Enroll',
  'Deploying',
  'Undeploying',
  'Deploy Failed',
  'Undeploy Failed',
  'Dropout'
];
const abnormalIssueType = [
  'POD_ABNORMAL',
  'RESOURCE_ABNORMAL',
  'RUNNING_ABNORMAL',
  'DNS_ABNORMAL',
  'RESTORE_OVERTIME',
  'Application_rbd_usage_over_90%',
  'Application_rbd_space_is_less_than_2G',
  'Pod_mem_usage_over_90%',
  'Pod_cpu_usage_over_90%'
];
const backupIssueType = [
  'Delete snapshot error',
  'Import backupfile error',
  'Unable to connect ceph cluster',
  'Create image error',
  'Create snapshot error',
  'Export backupfile error',
  'Get backupfile error',
  'Get rbd image error',
  'Get statefulset error',
  'Start pod faided',
  'Stop pod faided',
  'Unable to connect kube cluster',
  'Application does not exist',
  'Backup Overtime'
];
const abnormalStatus = ['Pending', 'Cancelled', 'Processing', 'Closed', 'AutoEmail'];
const packageNumber = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0~5',
  '6~10',
  '10~20',
  '20+'
];
const billingCycle = [
  { text: 'One Time', value: 0 },
  { text: 'Monthly', value: 1 },
  { text: 'Quarterly', value: 3 },
  { text: 'Semi-Annually', value: 6 },
  { text: 'Annually', value: 12 },
  { text: 'Biennially', value: 24 },
  { text: 'Triennially', value: 36 },
  { text: 'Quadrennially', value: 48 },
  { text: 'Quinquennially', value: 60 }
];

const fullManaged = ['Support Reload OS', 'Support IPMI', 'No IPMI'];
const BillingSettingArray = [
  {
    name: 'Invoice Generation Before Package Due Date',
    key: 'invoice_gen_days_before_due_date',
    val: ''
  },
  { name: 'Termination After Suspension', key: 'auto_terminate_days', val: '' },
  { name: 'Suspension For Overdue APPs', key: 'auto_suspension_days', val: '' },
  {
    name: 'Reminder Email Before A Credit Card’s Expiration Date',
    key: 'auto_credit_card_remind_days',
    val: ''
  },
  {
    name: 'Suspension For APPs Submitted Cancellation Requests',
    key: 'cancellation_request_suspend_days',
    val: ''
  },
  { name: 'Late Fee Adding After Due Date', key: 'late_fee_add_days_after_due_date', val: '' },
  { name: 'Late Fee Per Day', key: 'late_fee_per_day', val: '' },
  {
    name: '1st Overdue Reminder After Package Due Date',
    key: 'first_overdue_reminder_days',
    val: ''
  },
  {
    name: '2nd Overdue Reminder After Package Due Date',
    key: 'second_overdue_reminder_days',
    val: ''
  },
  {
    name: '3rd Overdue Reminder After Package Due Date',
    key: 'third_overdue_reminder_days',
    val: ''
  },
  {
    name: '1st Reminder To Complete Info Before The End Of Free Trial',
    key: 'complete_info_days_before_trial_period_end',
    val: ''
  },
  {
    name: '2nd Reminder To Complete Info Before The End Of Free Trial',
    key: 'second_reminder_days_before_trial_period_end',
    val: ''
  },
  {
    name: 'Short Trial Reminder To Complete Info Before The End Of Free Trial',
    key: 'short_complete_info_days_before_trial_period_end',
    val: ''
  },
  {
    name: 'Re-capture A Credit Card That Failed The First Charge',
    key: 'next_charge_invoice_days_record',
    val: ''
  },
  {
    name: 'Cancellation Of New Orders Without Deploying APPs',
    key: 'days_to_cancellation_of_new_order_without_deploying_apps',
    val: ''
  },
  { name: 'The first survey after app installation', key: 'first_return_visit_days', val: '' },
  {
    name: 'The second survey after app installation ',
    key: 'secondvisitinforoutine_days',
    val: ''
  },
  {
    name: 'Payment reminder before due date',
    key: 'invoicewilloverdueremindinforoutine_days',
    val: ''
  },
  {
    name: 'No-order reminder after registration--(hours)',
    key: 'none_order_reminder_hour',
    val: ''
  },
  { name: 'Suspension reminder after due date', key: 'suspend_reminder_days', val: '' },
  { name: 'Cancellation reminder after due date', key: 'invoice_cancel_reminder_days', val: '' },
  { name: 'FTP service duration--(hours)', key: 'ftp_assist_survival_time', val: '' },
  { name: 'Maximum number of firewall rules', key: 'app_ip_rules_max_number', val: '' },
  { name: 'Trail APP max number', key: 'trial_app_max_number', val: '' },
  { name: 'Maximum number of trial APP per product', key: 'trial_app_product_max_number', val: '' },
  {
    name: 'Maximum number of concurrent trial APP per product',
    key: 'trial_app_concurrent_max_num',
    val: ''
  },
  { name: 'Auto Verify Mini Score', key: 'auto_vefify_mini_score', val: '' },
  { name: 'Card Gateway', key: 'card_gateway', val: '' },
  { name: 'Maximum number of Split Payment', key: 'split_payment_limit', val: '' },
  { name: 'Minimux amount of per payment', key: 'min_payment_per_time', val: '' },
  { name: 'Created', key: 'created', val: '' },
  { name: 'Last Updated', key: 'last_updated', val: '' }
];
const vpsType = ['GPU Hosting Server', 'Linux Hosting Server', 'Windows Hosting Server'];
const SignUpSettingArray = [
  { name: 'Client Information Mode', key: 'client_info_config', val: '' },
  { name: 'Email Validation Mode', key: 'email_verify_config', val: '' },
  { name: 'Signup Robot Protection', key: 'need_robot_protection', val: '' },
  { name: 'Login Robot Protection', key: 'login_need_robot_protection', val: '' },
  { name: 'Fingerprint', key: 'fingerprint_enabled', val: '' },
  { name: 'Fingerprint Check Cycle(hours)', key: 'fingerprint_check_cycle_hours', val: '' },
  { name: 'IP Check', key: 'ip_check_enabled', val: '' },
  { name: 'Login Event Check Time Window(minutes)', key: 'login_event_check_time_window', val: '' },
  { name: 'Login Event Check Threshold', key: 'login_event_check_threshold', val: '' },
  {
    name: 'Login Event Protection Release Time Window(minutes)',
    key: 'login_event_protection_release_time_window',
    val: ''
  },
  {
    name: 'Login Event Protection Release Threshold',
    key: 'login_event_protection_release_threshold',
    val: ''
  }
];

const colorMap = {
  Active: 'success',
  Withdrawn: 'success',
  Unconfirmed: '#F57D0E',
  Online: 'success',
  Offline: '#ccc',
  Inactive: '#ccc',
  Deleted: '#ccc',
  Free: '#ccc',
  Unverified: 'accent',
  Approved: 'success',
  Success: 'success',
  Waiting: 'info',
  Deploying: 'warning',
  Withdrawing: 'warning',
  Deleting: 'warning',
  Processing: 'blue',
  Process: 'blue',
  Following: 'blue',
  Pending: 'warning',
  Initial: 'warning',
  'Pending Activation': 'warning',
  'Pending Removal': 'warning',
  'Reissue pending': 'warning',
  'Reissue Pending': 'warning',
  Good: 'success',
  Uncertain: 'rgba(220, 228, 92, 1)',
  Fraud: 'error',
  Suspended: 'blue',
  Cancelled: '#ccc',
  Closed: '#ccc',
  Paid: 'success',
  'Partially Paid': '#5574E5',
  Unpaid: 'error',
  Draft: 'info',
  'Fully Refunded': '#ab099b',
  'Charged Back': '#ab099b',
  'Pending Refund': '#FB8B24',
  Adding: '#FB8B24',
  'Partially Refunded': '#d89be4',
  Normal: 'success',
  Succeeded: 'success',
  Running: 'success',
  Installing: '#E6A23C',
  DeleteFailed: '#D9001B',
  Rollback: '#0688d9',
  Clear: 'green',
  Restricted: '#ff5252',
  Unrestricted: '#1867c0',
  Available: '#1867c0',
  WhiteListed: '#4caf50',
  AutoEmail: '#fb8c00'
};

const deviceType = ['CPU', 'Memory', 'Power', 'Disk', 'Chassis', 'Motherboard'];
const dataCenters = ['Denver', 'Dallas'];
const trialType = ['None', 'Requested', 'Approved', 'Rejected'];
export {
  company,
  perUlrMap,
  orderStatus,
  shellURL,
  productType,
  chatURL,
  deviceType,
  dataCenters,
  packageNumber,
  backupIssueType,
  BillingSettingArray,
  SignUpSettingArray,
  cancellationReasons,
  billingCycle,
  jobStatus,
  jobType,
  FeedBackType,
  abnormalIssueType,
  abnormalStatus,
  paymentMethod,
  colorMap,
  domain,
  status,
  packageCategory,
  packageStatus,
  deployStatus,
  cancellationTypes,
  invoiceStatus,
  invoiceTypes,
  app_key,
  limitSize,
  clientStatus,
  clientType,
  serviceStatus,
  serviceType,
  rentalServerStatus,
  fullManaged,
  vpsType,
  trialType,
  dedicatedServiceStatus,
  dedicatedDeploymentStatus,
  commissionStatus,
  withdrawalStatus,
  sslStatus,
  sslPackageStatus,
  domainStatus,
  eventTypeDown,
  eventTypeWarning,
  domainPackageStatus
};
