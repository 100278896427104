import Vue from 'vue';
import vuetify from './plugins/vuetifyExt';
import App from './App.vue';
import './permission';
import router from './router';
import store from './store';
// import VueMeta from 'vue-meta'
import i18n from '@/i18n';
import './plugins';
// import 'font-awesome/css/font-awesome.min.css'
import '@/assets/css/base/index.scss';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import 'vue-easytable/libs/theme-default/index.css';
import VueEasytable from 'vue-easytable';

Vue.config.productionTip = false;
Viewer.setDefaults({
  toolbar: {
    zoomIn: 1,
    zoomOut: 1,
    prev: false,
    play: {
      show: 1,
      size: 'large'
    },
    next: false,
    rotateLeft: 1,
    rotateRight: 1,
    oneToOne: 1,
    reset: 1,
    flipHorizontal: false,
    flipVertical: false
  }
});
// Vue.use(VueMeta, {
//     refreshOnceOnNavigation: true
// })
Vue.use(Viewer);
Vue.use(VueEasytable);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  beforeCreate() {
    // Install the global event bus
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App)
}).$mount('#app');
